import { Calendar } from 'react-date-range';
import { ru } from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setNewDate } from '../../../store/Calendar/calendar';

const CalendarSelector = () => {
    const date: Date = useSelector((state: RootState) => state.calendar.date);
    const dispatch = useDispatch();

    return (
        <Calendar date={date} onChange={(newDate) => dispatch(setNewDate(newDate))} locale={ru} showDateDisplay={false} />
    );
}

export default CalendarSelector;