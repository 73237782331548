import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialOptionsState = {} as { [name: string]: { value: string } }

export const options = createSlice(
    {
        name: 'calendarDate',
        initialState: initialOptionsState,
        reducers: {
            setOptions: (state, action: PayloadAction<{ name: string, value: string }>) => {
                state.name = { value: action.payload.value };
            },
        },
    }
)

export const { setOptions } = options.actions;

export default options.reducer;