import { useParams } from "react-router-dom";
import Table from "../Table/Table";
import styles from "./TableTitle.module.scss";

const GroupTable = () => {
    return (
        <div className={styles.main}>
            <div className={styles.title}>
                <h2 className={styles.groupName}>Группа №{useParams().groupID}</h2>
            </div>
            <Table />
        </div>
    );
}

export default GroupTable;