import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store";
import styles from "./Table.module.scss";
import TableItem from "./TableItem/TableItem";

const Table = () => {
    const date: Date = useSelector((state: RootState) => state.calendar.date);
    const groupID = useParams().groupID;

    return (
        <div className={styles.table}>
            {
                date.getDay() !== 0
                    ? Array.from(Array(7).keys()).map(index =>
                        <TableItem key={index} date={new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + index + 1)} />
                    )
                    : Array.from(Array(7).keys()).map(index =>
                        <TableItem key={index} date={new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6 + index)} />
                    )
            }
        </div>
    );
}

export default Table;