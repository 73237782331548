import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAvailable, ICreateBusyPeriod, ICreateEvent, ICreateGroup, ICreateLocation, ICreateSubject, IGenericKey, IGenericToken, IGroup, ILocation, IResponse, ISubject, ITimeRange, IUserBusyPeriod, IUserEvent, IUserKey, IUserToken, IUserPublicInfo, ICheckEmail, ICheckUsername, IGroupEvent, IUserRegister, IAvailablePermissions as IPermissions, IUserPrivateInfo, ITeachers } from "./models.api";

export const timewardAPI = createApi({
    reducerPath: "API",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.timeward.exteps.com/v1/",
        prepareHeaders: (headers) => {
            let token = "";
            document.cookie.split("; ").forEach(elem => elem.slice(0, 6) === "token=" ? token = decodeURIComponent(elem.slice(6)) : null);
            headers.set("Authorization", token);
            return headers;
        }
    }),
    endpoints: build => ({
        getGenericSession: build.mutation<IGenericKey, IGenericToken>({
            query: (body) => ({
                url: "session/generic",
                method: "POST",
                headers: {},
                body: body,
            })
        }),
        authentication: build.mutation<IUserKey, IUserToken>({
            query: (body) => ({
                url: "session/user",
                method: "POST",
                body: body,
            })
        }),
        registration: build.mutation<IResponse, IUserRegister>({
            query: (body) => ({
                url: "user/create",
                method: "POST",
                body: body,
            })
        }),
        getAllGroups: build.query<IGroup[], void>({
            query: () => ({
                url: "group/all",
                method: "GET",
            })
        }),
        createGroup: build.mutation<IResponse, ICreateGroup>({
            query: (body) => ({
                url: "group/create",
                method: "post",
                body: body
            })
        }),
        deleteGroup: build.mutation<IResponse, void>({
            query: (id) => ({
                url: `group/${id}`,
                method: "delete",
            })
        }),
        getGroupEvents: build.query<IGroupEvent[], void>({
            query: (id) => ({
                url: `group/${id}/events`,
                method: "get",
            })
        }),
        // checkGroupAvailability: build.query<IAvailable, ITimeRange>({
        //     query: (id, startTime, endTime) => ({
        //         url: `group/${id}/f`,
        //         method: "get",
        //         params: startTime, endTime,
        //     })
        // }),
        // getGroupSubjectStats: build.query<ISubjectStats[], void>({
        //     query: (id) => ({
        //         url: `group/${id}/stat/sub`,
        //         method: "get",
        //     })
        // }),
        getAllLocations: build.query<ILocation[], void>({
            query: () => ({
                url: "loc/all",
                method: "GET",
            })
        }),
        createLocation: build.mutation<IResponse, ICreateLocation>({
            query: (body) => ({
                url: "loc/create",
                method: "post",
                body: body
            })
        }),
        deleteLocation: build.mutation<IResponse, void>({
            query: (id) => ({
                url: `loc/${id}`,
                method: "delete",
            })
        }),
        // checkLocationAvailability: build.query<IAvailable, ITimeRange>({
        //     query: (id, data) => ({
        //         url: `loc/${id}/f`,
        //         method: "get",
        //         params: data,
        //     })
        // }),
        getAllSubjects: build.query<ISubject[], void>({
            query: () => ({
                url: "sub/all",
                method: "GET",
            })
        }),
        createSubject: build.mutation<IResponse, ICreateSubject>({
            query: (body) => ({
                url: "sub/create",
                method: "post",
                body: body,
            })
        }),
        deleteSubject: build.mutation<IResponse, void>({
            query: (id) => ({
                url: `sub/${id}`,
                method: "delete",
            })
        }),
        // getUserSubjectStats: build.query<ISubjectStats[], void>({
        //     query: () => ({
        //         url: "user/stat/sub",
        //         method: "get",
        //     })
        // }),
        getUserVacation: build.query<IUserBusyPeriod, void>({
            query: () => ({
                url: "user/stat/sub",
                method: "get",
            })
        }),
        getUserAllBusyPeriods: build.query<IUserBusyPeriod[], void>({
            query: (id) => ({
                url: `user/${id}/busy`,
                method: "get",
            })
        }),
        // getUserCreateBusyPeriod: build.query<IResponse, ICreateBusyPeriod>({
        //     query: (id, body) => ({
        //         url: `user/${id}/busy`,
        //         method: "post",
        //         body: body,
        //     })
        // }),
        // getUserEvents: build.query<IUserEvent[], ITimeRange>({
        //     query: (id, data) => ({
        //         url: `user/${id}/events`,
        //         method: "get",
        //         params: data,
        //     })
        // }),
        // checkUserAvailability: build.query<IAvailable, ITimeRange>({
        //     query: (id, data) => ({
        //         url: `user/${id}/f`,
        //         method: "get",
        //         params: data,
        //     })
        // }),
        createEvent: build.mutation<IResponse, ICreateEvent>({
            query: (body) => ({
                url: "event/create",
                method: "post",
                body: body,
            })
        }),
        deleteEvent: build.mutation<IResponse, void>({
            query: (id) => ({
                url: `event/${id}`,
                method: "delete",
            })
        }),
        getUserInfo: build.query<IUserPublicInfo, void>({
            query: () => ({
                url: "user/self",
                method: "GET",
            })
        }),
        checkUsernameAvailability: build.query<IResponse, ICheckUsername>({
            query: (username) => ({
                url: "user/a/n",
                params: username,
                method: "GET",
            })
        }),
        checkEmailAvailability: build.query<IResponse, ICheckEmail>({
            query: (email) => ({
                url: "user/a/e",
                params: email,
                method: "GET",
            })
        }),
        getAvailablePermissions: build.query<IPermissions, void>({
            query: () => ({
                url: "user/perm/all",
                method: "GET",
            })
        }),
        getAllUsers: build.query<ITeachers[], void>({
            query: () => ({
                url: "user/all",
                method: "GET",
            })
        }),
        // changeUserPermissions: build.mutation<IResponse, IPermissions>({
        //     query: (id, body) => ({
        //         url: `user/${id}/perm`,
        //         method: "post",
        //         body: body,
        //     })
        // }),
        getUserByID: build.query<IUserPrivateInfo, void>({
            query: (id) => ({
                url: `user/${id}`,
                method: "get",
            })
        }),
    })
});

export const {
    useGetGenericSessionMutation,
    useAuthenticationMutation,
    useGetAllGroupsQuery,
    useCreateGroupMutation,
    useDeleteGroupMutation,
    // useCheckGroupAvailabilityQuery,
    // useGetGroupSubjectStatsQuery,
    useGetAllLocationsQuery,
    // useCreateVacationMutation,
    useDeleteLocationMutation,
    // useCheckLocationAvailabilityQuery,
    useGetAllSubjectsQuery,
    useCreateSubjectMutation,
    useDeleteSubjectMutation,
    // useGetUserSubjectStatsQuery,
    useGetUserVacationQuery,
    useGetUserAllBusyPeriodsQuery,
    // useGetUserCreateBusyPeriodMutation,
    // useGetUserEventsQuery,
    useCreateEventMutation,
    useDeleteEventMutation,
    useGetUserInfoQuery,
    // useGetGroupEvents,
    useCheckUsernameAvailabilityQuery,
    useCheckEmailAvailabilityQuery,
    useGetAvailablePermissionsQuery,
    // useChangeUserPermissionsMutation,
    useGetAllUsersQuery,
} = timewardAPI;