import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import AdminList from "./AdminList/AdminList";
import CalendarSelector from "./CalendarSelector/CalendarSelector";
import FooterLogo from "./FooterLogo/FooterLogo";
import GroupList from "./GroupList/GroupList";
import styles from "./Main.module.scss";
import SignInTop from "./SignInTop/SignInTop";
import SubjectList from "./SubjectList/SubjectList";
import { useCookies } from "react-cookie";
import { useGetUserInfoQuery } from "../../store/API/timeward.api";

const Main = () => {
    const [cookies] = useCookies();
    const { data, isSuccess, isLoading, refetch } = useGetUserInfoQuery();
    useMemo(() => {
        try {
            refetch();
        }
        catch { }
    }, [cookies.token]);
    return (
        <div className={styles.wrapper}>
            <div className={styles.leftPanelWrapper}>
                <SignInTop />
                <div className={styles.leftPanel}>
                    {isSuccess
                        ? data.p.includes("edit_users")
                            ? <AdminList />
                            : data.p.includes("read_schedule")
                                ? <SubjectList search={true} />
                                : <GroupList />
                        : <GroupList />
                    }
                    <CalendarSelector />
                    <FooterLogo />
                </div>
            </div>
            <Outlet />
        </div>
    );
}

export default Main;