import { Outlet } from "react-router-dom";
import { useMemo } from "react";
import { useGetUserInfoQuery } from "../../store/API/timeward.api";
import { useCookies } from "react-cookie";

const Admin = () => {
    const [cookies] = useCookies();
    const { data, isSuccess, isLoading, refetch } = useGetUserInfoQuery();
    useMemo(() => {
        try {
            refetch();
        }
        catch { }
    }, [cookies.token]);
    return (
        <>
            {isSuccess && data?.p.includes("edit_users")
                ?
                <Outlet />
                : <p>у вас нет прав</p>
            }
        </>
    );
}

export default Admin;