import { Link } from "react-router-dom";
import styles from "./Groups.module.scss";

interface GroupItemInterface {
    groupID: number;
    groupNumber: string;
}

const GroupItem = (props: GroupItemInterface) => {
    return (
        <Link to={`/${props.groupID.toString()}`} className={styles.groupItem}>
            <p className={styles.groupName}>{props.groupNumber}</p>
        </Link>
    );
}

export default GroupItem;