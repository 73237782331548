import { ReactComponent as LogoSVG } from "../svg/logo.svg";
import { ReactComponent as ExtepsSVG } from "../svg/exteps.svg";
import "./SignInPage.scss";
import { useState, FormEvent } from "react";
import { IUserToken } from "../store/API/models.api";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useAuthenticationMutation } from "../store/API/timeward.api";

const EMAILREGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const SignInPage = () => {
    const [user, setUser] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordShow, setPasswordShow] = useState<boolean>(true);
    const [fetchAuthentication] = useAuthenticationMutation();
    const [cookies, setCookies] = useCookies(["token", "_ym_uid"]);
    const navigate = useNavigate();

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let data = {} as IUserToken;
        if (user.toLowerCase().match(EMAILREGEX)) data.e = user;
        else data.n = user;
        data.p = password;
        let yandexId = cookies._ym_uid;
        yandexId = yandexId.concat(yandexId.slice(0, 32 - yandexId.length));
        data.t = yandexId;
        try {
            const res = await fetchAuthentication(data).unwrap();
            setCookies("token", `User: ${res.k}`);
            toast.success("Успешно!");
            navigate("/");
        }
        catch {
            toast.error("Проверьте введённые данные");
        }
    }

    return (
        <div className="signInBox" style={{ "--pseudo-show-content": passwordShow ? `"\ue9ce"` : `"\ue9d1"` } as React.CSSProperties}>
            <div className="mainBox">
                <div className="logoBox">
                    <LogoSVG />
                    <ExtepsSVG style={{ width: "46%" }} />
                </div>
                <div className="loginBox">
                    <h3 className="loginTitle">Вход в систему</h3>
                    <form className="authForm" onSubmit={handleSubmit}>
                        <div className="authInputs">
                            <input value={user} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUser(event.currentTarget.value)} type="text" className="email" placeholder="Логин или e-mail" />
                            <div className="passwordDiv">
                                <input value={password} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.currentTarget.value)} type={passwordShow ? "password" : "text"} className="password" placeholder="Пароль" id="pass" />
                                <div className="showCB">
                                    <input type="checkbox" id="show" onClick={() => passwordShow ? setPasswordShow(false) : setPasswordShow(true)} />
                                    <label htmlFor="show">Показать пароль</label>
                                </div>
                            </div>
                        </div>
                        <div className="submit">
                            <input type="submit" value="Войти" className="signInSubmit" />
                            <div className="rememberCB">
                                <input type="checkbox" name="remember" id="remember" />
                                <label htmlFor="remember">Запомни меня</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignInPage;