import { ReactComponent as LogoSVG } from "../../../svg/logo.svg";
import { Link } from "react-router-dom";
import styles from "./SignInTop.module.scss";
import { useGetUserInfoQuery } from "../../../store/API/timeward.api";
import { useMemo } from "react";
import { useCookies } from "react-cookie";

const SignInTop = () => {
    const [cookies] = useCookies();
    const { data, isSuccess, isLoading, refetch } = useGetUserInfoQuery();
    useMemo(() => {
        try {
            refetch();
        }
        catch { }
    }, [cookies.token]);
    return (
        <div className={styles.leftPanel}>
            <div className={styles.signInSection}>
                <Link to="/" className={styles.logoSection}>
                    <LogoSVG />
                </Link>
                <hr className={styles.sep}></hr>
                {!isLoading && !isSuccess &&
                    <Link to="/sign-in" className={styles.signIn}>Войти</Link>
                }
                {
                    isSuccess &&
                    <p className={styles.signIn}>{data.n}</p>
                }
            </div>
        </div>
    );
}

export default SignInTop;