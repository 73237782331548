import { useGetAllLocationsQuery } from "../../../store/API/timeward.api";
import style from "../Admin.module.scss";
import listStyles from "../Lists/List.module.scss";
import { useState } from "react";

const Locations = () => {
    const [locationID, setLocationID] = useState<number>(-1);
    const { data, isSuccess, isLoading, isError } = useGetAllLocationsQuery();

    return (
        <div className={style.adminSettings}>
            <h2 className={style.title}>Локации</h2>
            <div className={style.creation}>
                <div className={style.creationSection}>
                    {/* <GroupList style={{ maxHeight: "65vh", height: "auto" }} /> */}
                    {/* <List element={<TeacherItems />} style={{ maxHeight: "65vh", height: "auto" }} /> */}
                    <input type="search" className={listStyles.searchInput} placeholder="Поиск" />
                    <div className={listStyles.list} style={{ maxHeight: "65vh", height: "auto" }}>
                        {isSuccess &&
                            data.map(element =>
                                <label key={element.id} className={listStyles.checkboxBtn}>
                                    <input type="radio" name="location" onClick={() => setLocationID(element.id)} checked={locationID === element.id} />
                                    <span>{element.n}</span>
                                </label>
                            )
                        }
                        {/* <label className={listStyles.checkboxBtn}>
                            <input type="radio" name="location" onClick={() => setLocationID(1)} checked={locationID === 1} />
                            <span>ауд. 404</span>
                        </label>
                        <label className={listStyles.checkboxBtn}>
                            <input type="radio" name="location" onClick={() => setLocationID(2)} checked={locationID === 2} />
                            <span>ауд. 404</span>
                        </label>
                        <label className={listStyles.checkboxBtn}>
                            <input type="radio" name="location" onClick={() => setLocationID(3)} checked={locationID === 3} />
                            <span>ауд. 404</span>
                        </label>
                        <label className={listStyles.checkboxBtn}>
                            <input type="radio" name="location" onClick={() => setLocationID(4)} checked={locationID === 4} />
                            <span>ауд. 404</span>
                        </label>
                        <label className={listStyles.checkboxBtn}>
                            <input type="radio" name="location" onClick={() => setLocationID(5)} checked={locationID === 5} />
                            <span>ауд. 404</span>
                        </label>
                        <label className={listStyles.checkboxBtn}>
                            <input type="radio" name="location" onClick={() => setLocationID(6)} checked={locationID === 6} />
                            <span>ауд. 404</span>
                        </label>
                        <label className={listStyles.checkboxBtn}>
                            <input type="radio" name="location" onClick={() => setLocationID(7)} checked={locationID === 7} />
                            <span>ауд. 404</span>
                        </label>
                        <label className={listStyles.checkboxBtn}>
                            <input type="radio" name="location" onClick={() => setLocationID(8)} checked={locationID === 8} />
                            <span>ауд. 404</span>
                        </label> */}


                    </div>
                    <div onClick={() => setLocationID(0)} className={style.buttonSave} style={{ alignSelf: "center" }}>Создать локацию</div>
                </div>
                <div className={style.creationSection}>
                    {locationID === 0
                        ?
                        <>
                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Введите название локации:</h4>
                                    <input type="text" className={style.inputText} spellCheck placeholder="Холл" />
                                </div>
                            </div>
                            <div className={style.buttonSave} style={{ alignSelf: "flex-start" }}>Сохранить</div>
                        </>
                        : locationID !== -1 &&
                        <>
                            <div className={style.typeChoose}>
                                <div className={style.viewSection}>
                                    <h4 className={style.creationSectionTitle}>Название локации:</h4>
                                    <h4 className={style.creationSectionTitle}>{locationID}</h4>
                                </div>
                            </div>
                            <div className={style.buttonSave} style={{ alignSelf: "flex-start" }}>Удалить</div>
                        </>
                    }

                </div>
            </div>
        </div>
    );
}

export default Locations;