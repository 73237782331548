import { configureStore } from "@reduxjs/toolkit";
import { timewardAPI } from "./API/timeward.api";
import { calendar } from "./Calendar/calendar";
import { options } from "./Calendar/createOptions";

export const store = configureStore({
    reducer: {
        [timewardAPI.reducerPath]: timewardAPI.reducer,
        calendar: calendar.reducer,
        options: options.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(timewardAPI.middleware),
    devTools: false
});

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>