import styles from "./FooterLogo.module.scss";
import { ReactComponent as ExtepsSVG } from "../../../svg/exteps.svg";

const FooterLogo = () => {
    return (
        <div className={styles.footer}>
            <a href="https://exteps.com" style={{ width: "100%" }}>
                <ExtepsSVG />
            </a>
            <p>2023</p>
        </div>
    );
}

export default FooterLogo;