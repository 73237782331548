import { useEffect, useState } from 'react';
import Main from './Outlet/Main/Main';
import { useCookies } from 'react-cookie';
import { useGetGenericSessionMutation } from './store/API/timeward.api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function getCookie(name: string): string | undefined {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches?.toString() ? decodeURIComponent(matches[1]) : undefined;
}

function App() {
    const [fetchToken] = useGetGenericSessionMutation();
    const [canRender, setCanRender] = useState<boolean>(false);
    const [, setCookies] = useCookies();

    async function getGeneric() {
        /**
         * Receiving generic token
        */
        let yandexId = getCookie("_ym_uid");
        if (yandexId) {
            let newToken = yandexId.concat(yandexId.slice(0, 32 - yandexId.length));
            const res = await fetchToken({ t: newToken }).unwrap();
            await setCookies("token", "Generic: " + res.k, { maxAge: 10080 });
            setCanRender(true);
        }
        else {
            await new Promise(f => setTimeout(f, 1000));
            getGeneric();
        }
    }

    useEffect(() => {
        if (!getCookie("token")) getGeneric();
        else setCanRender(true);
    }, []);

    return (
        <>
            {
                canRender
                    ? <>
                        <Main />
                        <ToastContainer
                            position="bottom-right"
                            autoClose={3000}
                            hideProgressBar={false}
                            closeOnClick
                            rtl={false}
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </>
                    : <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "auto", display: "block", shapeRendering: "auto" }} width="250px" height="250px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g transform="translate(50 42)">
                            <g transform="scale(0.8)">
                                <g transform="translate(-50 -50)">
                                    <polygon fill="#85a2b6" points="72.5 50 50 11 27.5 50 50 50">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" values="0 50 38.5;360 50 38.5" keyTimes="0;1"></animateTransform>
                                    </polygon>
                                    <polygon fill="#bbcedd" points="5 89 50 89 27.5 50">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" values="0 27.5 77.5;360 27.5 77.5" keyTimes="0;1"></animateTransform>
                                    </polygon>
                                    <polygon fill="#dce4eb" points="72.5 50 50 89 95 89">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" values="0 72.5 77.5;360 72 77.5" keyTimes="0;1"></animateTransform>
                                    </polygon>
                                </g>
                            </g>
                        </g>
                    </svg>
            }
        </>
    );
}

export default App;
