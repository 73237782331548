import { Link } from "react-router-dom";
import styles from "./AdminList.module.scss";

const AdminList = () => {
    return (
        <div className={styles.adminList}>
            <Link to="/admin/timetable" className={styles.adminLink}>Расписание</Link>
            <Link to="/admin/teachers" className={styles.adminLink}>Учителя</Link>
            <Link to="/admin/groups" className={styles.adminLink}>Группы</Link>
            <Link to="/admin/subjects" className={styles.adminLink}>Дисциплины</Link>
            <Link to="/admin/locations" className={styles.adminLink}>Локации</Link>
        </div>
    );
}

export default AdminList;