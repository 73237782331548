import { useGetAllSubjectsQuery } from "../../../store/API/timeward.api";
import style from "../Admin.module.scss";
import listStyles from "../Lists/List.module.scss";
import { useState } from "react";

const Subjects = () => {
    const [subjectID, setSubjectID] = useState<number>(-1);
    const { data, isSuccess } = useGetAllSubjectsQuery();

    return (
        <div className={style.adminSettings}>
            <h2 className={style.title}>Дисциплины</h2>
            <div className={style.creation}>
                <div className={style.creationSection}>
                    {/* <GroupList style={{ maxHeight: "65vh", height: "auto" }} /> */}
                    {/* <List element={<TeacherItems />} style={{ maxHeight: "65vh", height: "auto" }} /> */}
                    <input type="search" className={listStyles.searchInput} placeholder="Поиск" />
                    <div className={listStyles.list} style={{ maxHeight: "65vh", height: "auto" }}>
                        {isSuccess &&
                            data.map(element =>
                                <label key={element.id} className={listStyles.checkboxBtn}>
                                    <input type="radio" name="subject" onClick={() => setSubjectID(element.id)} checked={subjectID === element.id} />
                                    <span>{element.n}</span>
                                </label>
                            )
                        }
                    </div>
                    <div onClick={() => setSubjectID(0)} className={style.buttonSave} style={{ alignSelf: "center" }}>Создать дисциплину</div>
                </div>
                <div className={style.creationSection}>
                    {subjectID === 0
                        ?
                        <>
                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Введите название дисциплины:</h4>
                                    <input type="text" className={style.inputText} spellCheck placeholder="Математика" />
                                </div>
                            </div>
                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Введите требуюмую выработку (в часах):</h4>
                                    <input type="text" className={style.inputText} spellCheck placeholder="36" />
                                </div>
                            </div>
                            <div className={style.buttonSave} style={{ alignSelf: "flex-start" }}>Сохранить</div>
                        </>
                        : subjectID !== -1 &&
                        <>
                            <div className={style.typeChoose}>
                                <div className={style.viewSection}>
                                    <h4 className={style.creationSectionTitle}>Дисциплина:</h4>
                                    <h4 className={style.creationSectionTitle}>{subjectID}</h4>
                                </div>
                            </div>
                            <div className={style.typeChoose}>
                                <div className={style.viewSection}>
                                    <h4 className={style.creationSectionTitle}>Выработка (в часах):</h4>
                                    <h4 className={style.creationSectionTitle}>{subjectID}</h4>
                                </div>
                            </div>
                            <div className={style.buttonSave} style={{ alignSelf: "flex-start" }}>Удалить</div>
                        </>
                    }

                </div>
            </div>
        </div>
    );
}

export default Subjects;