import SubjectItem from "./SubjectItem";
import styles from "./SubjectList.module.scss";

const SubjectList = ({ search = true }: { search: boolean }) => {

    return (
        <>
            {search && <input type="search" className={styles.searchInput} placeholder="Поиск" />}
            <div className={styles.subjectList}>
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
                <SubjectItem />
            </div>
        </>
    );
}

export default SubjectList;