import { ReactComponent as LogoSVG } from "../svg/logo.svg";
import { ReactComponent as ExtepsSVG } from "../svg/exteps.svg";

const HelloWorld = () => {
    return (
        <div className="mainBox" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="logoBox">
                {/* <img src="./static/logo.svg" alt="Логотип TimeWard" className="logo" />
                <img
                    src="./static/logoExteps.svg"
                    alt="Логотип Exception:teapots"
                    className="logoExteps"
                /> */}
                <LogoSVG style={{width: "20rem"}}/>
                <ExtepsSVG style={{ width: "46%" }} />
            </div>
            <div className="textBox">
                <h1>Удобное интерактивное расписание для университетов</h1>
                <p>Выберите группу или войдите</p>
            </div>

        </div>
    );
}

export default HelloWorld;