import { CSSProperties } from "react";
import GroupItem from "./GroupItem";
import styles from "./Groups.module.scss";
import { useGetAllGroupsQuery } from "../../../store/API/timeward.api";
import { toast } from "react-toastify";

const GroupList = (props: { style?: CSSProperties }) => {

    const { data, isSuccess, isLoading, isError } = useGetAllGroupsQuery();

    if (isError) toast.error("Кажется, произошла ошибка. Перезагрузите страницу или очистите историю")

    return (
        <>
            <input type="search" className={styles.searchInput} placeholder="Поиск" />
            <div className={styles.groupList} style={props.style}>
                {
                    isSuccess &&
                    data.map(group =>
                        <GroupItem key={group.id} groupID={group.id} groupNumber={group.n} />
                    )
                }
                {isLoading &&
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "auto", display: "block", shapeRendering: "auto" }} width="250px" height="250px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g transform="translate(50 42)">
                            <g transform="scale(0.8)">
                                <g transform="translate(-50 -50)">
                                    <polygon fill="#85a2b6" points="72.5 50 50 11 27.5 50 50 50">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" values="0 50 38.5;360 50 38.5" keyTimes="0;1"></animateTransform>
                                    </polygon>
                                    <polygon fill="#bbcedd" points="5 89 50 89 27.5 50">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" values="0 27.5 77.5;360 27.5 77.5" keyTimes="0;1"></animateTransform>
                                    </polygon>
                                    <polygon fill="#dce4eb" points="72.5 50 50 89 95 89">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.25s" values="0 72.5 77.5;360 72 77.5" keyTimes="0;1"></animateTransform>
                                    </polygon>
                                </g>
                            </g>
                        </g>
                    </svg>
                }
            </div>
        </>
    );
}

export default GroupList;