import { useState } from "react";
import listStyles from "../Lists/List.module.scss";
import style from "../Admin.module.scss";
import SubjectList from "../../Main/SubjectList/SubjectList";
import { DateRange, Range } from 'react-date-range';
import { ru } from 'react-date-range/dist/locale';
import { useGetAllUsersQuery } from "../../../store/API/timeward.api";

const Teachers = () => {
    const [teacherID, setTeacherID] = useState<string>("-1");
    const [state, setState] = useState<Range[]>([
        {
            startDate: new Date(),
            endDate: undefined,
            key: 'selection'
        }
    ]);
    const { data, isSuccess, isLoading, isError } = useGetAllUsersQuery();
    console.log(state);
    return (
        <div className={style.adminSettings}>
            <h2 className={style.title}>Учителя</h2>
            <div className={style.creation}>
                <div className={style.creationSection}>
                    {/* <GroupList style={{ maxHeight: "65vh", height: "auto" }} /> */}
                    {/* <List element={<TeacherItems />} style={{ maxHeight: "65vh", height: "auto" }} /> */}
                    <input type="search" className={listStyles.searchInput} placeholder="Поиск" />
                    <div className={listStyles.list} style={{ maxHeight: "65vh", height: "auto" }}>
                        {isSuccess &&
                            data.map(element =>
                                <label key={element.id} className={listStyles.checkboxBtn}>
                                    <input type="radio" name="teacher" onClick={() => setTeacherID(element.id)} checked={teacherID === element.id} />
                                    <span>{`${element.n} ${element.s} ${element.pt}`}</span>
                                </label>
                            )
                        }
                    </div>
                    <div onClick={() => setTeacherID("0")} className={style.buttonSave} style={{ alignSelf: "center" }}>Создать учителя</div>
                </div>
                <div className={style.creationSection}>
                    {teacherID === "0"
                        ?
                        <>
                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Введите фамилию:</h4>
                                    <input type="text" className={style.inputText} spellCheck placeholder="Иванов" />
                                </div>
                            </div>

                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Введите имя:</h4>
                                    <input type="text" className={style.inputText} spellCheck placeholder="Иван" />
                                </div>
                            </div>

                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Введите отчество:</h4>
                                    <input type="text" className={style.inputText} spellCheck placeholder="Иванович" />
                                </div>
                            </div>

                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Введите почту:</h4>
                                    <input type="email" className={style.inputText} spellCheck placeholder="ivan@example.com" />
                                </div>
                            </div>
                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Введите пароль:</h4>
                                    <input type="password" className={style.inputText} spellCheck placeholder="********" />
                                </div>
                            </div>
                            <div className={style.buttonSave} style={{ alignSelf: "flex-start" }}>Сохранить</div>
                        </>
                        : teacherID !== "-1" &&
                        <>
                            <div className={style.typeChoose}>
                                <div className={style.viewSection}>
                                    <h4 className={style.creationSectionTitle}>ФИО:</h4>
                                    <h4 className={style.creationSectionTitle}>{teacherID}</h4>
                                </div>
                            </div>
                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <h4 className={style.creationSectionTitle}>Дисциплины:</h4>
                                    <SubjectList search={false} />
                                </div>
                            </div>
                            <div className={style.typeChoose}>
                                <div className={style.creationSection}>
                                    <div className={style.viewSection}>
                                        <h4 className={style.creationSectionTitle}>Отпуск:</h4>
                                        <h4 className={style.creationSectionTitle}>{teacherID}</h4>
                                    </div>
                                    <DateRange moveRangeOnFirstSelection={false} ranges={state} onChange={item => setState([item.selection])} className={style.calendar} locale={ru} showDateDisplay={false} />
                                </div>
                            </div>
                            <div className={style.buttonSave} style={{ alignSelf: "flex-start" }}>Обновить отпуск</div>
                            <div className={style.buttonSave} style={{ alignSelf: "flex-start" }}>Удалить учителя</div>
                        </>
                    }

                </div>
            </div>
        </div>
    );
}

export default Teachers;