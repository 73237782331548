import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from "./store";

import "./index.scss";

import App from './App';
import TableTitle from './Outlet/Main/TableTitle/TableTitle';
import SignInPage from './SignInPage/SignInPage';
import HelloWorld from './HelloWorld/HelloWorld';
import Admin from './Outlet/Admin/Admin';
import TimeTable from './Outlet/Admin/TimeTable/TimeTable';
import Teachers from './Outlet/Admin/Teachers/Teachers';
import Groups from './Outlet/Admin/Groups/Groups';
import Subjects from './Outlet/Admin/Subjects/Subjects';
import Locations from './Outlet/Admin/Locations/Locations';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <HelloWorld />
            },
            {
                path: "sign-in",
                element: <SignInPage />
            },
            {
                path: "admin",
                element: <Admin />,
                children: [
                    {
                        path: "timetable",
                        element: <TimeTable />
                    },
                    {
                        path: "teachers",
                        element: <Teachers />
                    },
                    {
                        path: "groups",
                        element: <Groups />
                    },
                    {
                        path: "subjects",
                        element: <Subjects />
                    },
                    {
                        path: "locations",
                        element: <Locations />
                    },
                ]
            },
            {
                path: ":groupID",
                element: <TableTitle />
            },
        ]
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
);