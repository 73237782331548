import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface ICalendarDate {
    date: Date
};

const initialDateState = {
    date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
} as ICalendarDate;

export const calendar = createSlice(
    {
        name: 'calendarDate',
        initialState: initialDateState,
        reducers: {
            setNewDate: (state, action: PayloadAction<Date>) => {
                state.date = action.payload;
            },
        },
    }
)

export const { setNewDate } = calendar.actions;

export default calendar.reducer;