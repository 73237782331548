import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import styles from "./TableItem.module.scss";
import "./event.scss";
import { useParams } from "react-router-dom";

const TableItem = ({ date }: { date: Date }) => {
    const globalDate: Date = useSelector((state: RootState) => state.calendar.date);
    const groupID = useParams().groupID;

    const getHeight = (unixStart: Date, unixStop: Date) => {
        const deltaTime = (unixStop.getTime() - unixStart.getTime()) / 48000;
        return deltaTime.toString() + "px";
    }

    const getMarginTop = (unixStart: Date) => {
        const defaultMargin = 6;
        const startDay = new Date(unixStart);
        startDay.setHours(8, 0, 0, 0);
        const margin_time = (unixStart.getTime() - startDay.getTime()) / 48000 + defaultMargin;
        return margin_time.toString() + "px";
    }
    // new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 30)
    return (
        <div className={`${globalDate.getDate() === date.getDate() ? styles.isNow : ""} ${styles.item}`}>
            <div className={styles.day}><p>{date.toLocaleString("ru", { weekday: "long" })}</p><p>{date.toLocaleString("ru", { dateStyle: "short" }).split(".").slice(0, 2).join(".")}</p></div>
            <div className={styles.subjects}>
                {
                    groupID === '1' &&
                    <>
                        {
                            date.getDate() === 3 &&
                            <>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 30)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8)) }}>
                                    <h3>Математика</h3>
                                    <h4>Пара</h4>
                                    <h4>Касимов А.М.</h4>
                                    <p>ауд. 214</p>
                                    <p>8:00-9:30</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 20)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50)) }}>
                                    <h3>Русский язык</h3>
                                    <h4>Пара</h4>
                                    <h4>Вахрушев И.Ю.</h4>
                                    <p>ауд. 301</p>
                                    <p>9:50-11:20</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30)) }}>
                                    <h3>Химия</h3>
                                    <h4>Пара</h4>
                                    <h4>Касимов А.М.</h4>
                                    <p>ауд. 308</p>
                                    <p>11:30-13:00</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14, 40)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10)) }}>
                                    <h3>Литература</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 402</p>
                                    <p>13:10-14:40</p>
                                </div>
                            </>
                        }
                        {
                            date.getDate() === 4 &&
                            <>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 10, 20), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 50)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 10, 20)) }}>
                                    <h3>Русский язык</h3>
                                    <h4>Пара</h4>
                                    <h4>Вахрушев И.Ю.</h4>
                                    <p>ауд. 301</p>
                                    <p>10:20-11:50</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 30)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12)) }}>
                                    <h3>Химия</h3>
                                    <h4>Пара</h4>
                                    <h4>Касимов А.М.</h4>
                                    <p>ауд. 308</p>
                                    <p>12:00-13:30</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 40), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 15, 10)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 40)) }}>
                                    <h3>Литература</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 402</p>
                                    <p>13:40-15:10</p>
                                </div>
                            </>
                        }
                        {
                            date.getDate() === 5 &&
                            <>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 30)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8)) }}>
                                    <h3>Русский язык</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 219</p>
                                    <p>8:00-9:30</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 20)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50)) }}>
                                    <h3>ОБЖ</h3>
                                    <h4>Пара</h4>
                                    <h4>Касимов А.М.</h4>
                                    <p>ауд. 302</p>
                                    <p>9:50-11:20</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30)) }}>
                                    <h3>ИБ</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 205</p>
                                    <p>11:30-13:00</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14, 40)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10)) }}>
                                    <h3>Химия</h3>
                                    <h4>Пара</h4>
                                    <h4>Касимов А.М.</h4>
                                    <p>ауд. 308</p>
                                    <p>13:10-14:20</p>
                                </div>
                            </>
                        }
                        {
                            date.getDate() === 6 &&
                            <>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 30)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8)) }}>
                                    <h3>Информатика</h3>
                                    <h4>Пара</h4>
                                    <h4>Вахрушев И.Ю.</h4>
                                    <p>ауд. 306</p>
                                    <p>8:00-9:30</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 20)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50)) }}>
                                    <h3>Информатика</h3>
                                    <h4>Пара</h4>
                                    <h4>Вахрушев И.Ю.</h4>
                                    <p>ауд. 306</p>
                                    <p>9:50-11:20</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30)) }}>
                                    <h3>ИБ</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 205</p>
                                    <p>11:30-13:00</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14, 40)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10)) }}>
                                    <h3>ИБ</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 205</p>
                                    <p>13:10-14:20</p>
                                </div>
                            </>
                        }
                        {
                            date.getDate() === 7 &&
                            <>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 30)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8)) }}>
                                    <h3>Русский язык</h3>
                                    <h4>Пара</h4>
                                    <h4>Вахрушев И.Ю.</h4>
                                    <p>ауд. 301</p>
                                    <p>8:00-9:30</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 20)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50)) }}>
                                    <h3>ОБЖ</h3>
                                    <h4>Пара</h4>
                                    <h4>Касимов А.М.</h4>
                                    <p>ауд. 302</p>
                                    <p>9:50-11:20</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30)) }}>
                                    <h3>Дизайн</h3>
                                    <h4>Пара</h4>
                                    <h4>Касимов А.М.</h4>
                                    <p>ауд. 302</p>
                                    <p>11:30-13:00</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14, 40)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10)) }}>
                                    <h3>История</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 404</p>
                                    <p>13:10-14:20</p>
                                </div>
                            </>
                        }
                        {
                            date.getDate() === 8 &&
                            <>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 30)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8)) }}>
                                    <h3>Русский язык</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 219</p>
                                    <p>8:00-9:30</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 20)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 50)) }}>
                                    <h3>ОБЖ</h3>
                                    <h4>Пара</h4>
                                    <h4>Касимов А.М.</h4>
                                    <p>ауд. 302</p>
                                    <p>9:50-11:20</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 30)) }}>
                                    <h3>ИБ</h3>
                                    <h4>Пара</h4>
                                    <h4>Швиндт Р.В.</h4>
                                    <p>ауд. 205</p>
                                    <p>11:30-13:00</p>
                                </div>
                                <div className="event" style={{ minHeight: getHeight(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10), new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14, 40)), marginTop: getMarginTop(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 10)) }}>
                                    <h3>Информатика</h3>
                                    <h4>Пара</h4>
                                    <h4>Вахрушев И.Ю.</h4>
                                    <p>ауд. 306</p>
                                    <p>13:10-14:20</p>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default TableItem;