import { useState } from "react";
import { Calendar } from "react-date-range";
import { ru } from 'react-date-range/dist/locale';
import listStyles from "../Lists/List.module.scss";
import style from "./TimeTable.module.scss";
import "./time.scss";
import { useGetAllGroupsQuery, useGetAllLocationsQuery, useGetAllSubjectsQuery, useGetAllUsersQuery } from "../../../store/API/timeward.api";

const TimeTable = () => {
    const [groupID, setGroupID] = useState<number[]>([]);
    const [locationID, setLocationID] = useState<number>();
    const [date, setDate] = useState<Date>(new Date());
    const [isSubject, setIsSubject] = useState<boolean>(true);
    const [teacherID, setTeacherID] = useState<string>();
    const [subjectID, setSubjectID] = useState<number>();
    const [eventName, setEventName] = useState<string>();

    const { data, isSuccess } = useGetAllGroupsQuery();
    const { data: locs, isSuccess: locsSuccess } = useGetAllLocationsQuery();
    const { data: teachers, isSuccess: teacherSuccess } = useGetAllUsersQuery();
    const { data: subjs, isSuccess: subjsSuccess } = useGetAllSubjectsQuery();

    const handleGroups = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked && !(event.currentTarget.value in groupID)) setGroupID([...groupID, Number.parseInt(event.currentTarget.value)]);
        if (!event.currentTarget.checked) {
            const helping = groupID.slice();
            helping.splice(helping.indexOf(parseInt(event.currentTarget.value)), 1);
            setGroupID([...helping]);
        }
    }

    const handleSubmit = () => {
        console.log(groupID, locationID, date, isSubject, teacherID, subjectID, eventName);
    }

    return (
        <div className={style.adminSettings}>
            <h2 className={style.title}>Составление расписания</h2>
            <div className={style.creation}>
                <div className={style.creationSection}>
                    <h4 className={style.creationSectionTitle}>Выберите группы:</h4>
                    {/* <input type="search" className={listStyles.searchInput} placeholder="Поиск" /> */}
                    <div className={listStyles.list}>
                        {isSuccess
                            && data.map(element =>
                                <label key={element.id} className={listStyles.checkboxBtn}>
                                    <input type="checkbox" name="group" onChange={handleGroups} value={element.id} />
                                    <span>{element.n}</span>
                                </label>
                            )
                        }
                    </div>
                </div>
                <div className={style.creationSection}>
                    <h4 className={style.creationSectionTitle}>Выберите локацию:</h4>
                    {/* <input type="search" className={listStyles.searchInput} placeholder="Поиск" /> */}
                    <div className={listStyles.list}>
                        {locsSuccess &&
                            locs.map(element =>
                                <label key={element.id} className={listStyles.checkboxBtn}>
                                    <input type="radio" name="location" onClick={() => setLocationID(element.id)} checked={locationID === element.id} />
                                    <span>{element.n}</span>
                                </label>
                            )
                        }
                    </div>
                </div>
                <div className={style.creationSection}>
                    <h4 className={style.creationSectionTitle}>Выберите время:</h4>
                    <div className={style.dateSection}>
                        <Calendar date={date} onChange={(date: Date) => setDate(date)} className={style.calendar} locale={ru} showDateDisplay={false} />
                        <div className="timeManagment">
                            <div className="timeStart">
                                <label htmlFor="time-start">Начало:</label>
                                <input type="time" id="time-start" />
                            </div>
                            <div className="timeStop">
                                <label htmlFor="time-stop">Конец:</label>
                                <input type="time" id="time-stop" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.typeChoose}>
                <form className={style.creationSection}>
                    <h4 className={style.creationSectionTitle}>Выберите тип:</h4>
                    <label className={style.checkboxBtn}>
                        <input type="radio" name="type" defaultChecked onChange={() => { setIsSubject(true) }} />
                        <span>Пара</span>
                    </label>
                    <label className={style.checkboxBtn}>
                        <input type="radio" name="type" onChange={() => { setIsSubject(false) }} />
                        <span>Мероприятие</span>
                    </label>
                </form>
            </div>
            {isSubject
                ?
                <div className={style.creation}>
                    <div className={style.creationSection}>
                        <h4 className={style.creationSectionTitle}>Выберите преподавателя:</h4>
                        {/* <input name="search-teachers" type="search" className={listStyles.searchInput} placeholder="Поиск" /> */}
                        <div className={listStyles.list}>
                            {teacherSuccess &&
                                teachers.map(element =>
                                    <label key={element.id} className={listStyles.checkboxBtn}>
                                        <input type="radio" name="teacher" onClick={() => setTeacherID(element.id)} checked={teacherID === element.id} />
                                        <span>{`${element.n} ${element.s} ${element.pt}`}</span>
                                    </label>
                                )
                            }
                        </div>
                    </div>
                    <div className={style.creationSection}>
                        <h4 className={style.creationSectionTitle}>Выберите дисциплину:</h4>
                        {/* <input type="search" className={listStyles.searchInput} placeholder="Поиск" /> */}
                        <div className={listStyles.list}>
                            {subjsSuccess &&
                                subjs.map(element =>
                                    <label key={element.id} className={listStyles.checkboxBtn}>
                                        <input type="radio" name="subject" onClick={() => setSubjectID(element.id)} checked={subjectID === element.id} />
                                        <span>{element.n}</span>
                                    </label>
                                )
                            }
                        </div>
                    </div>
                </div>
                :
                <div className={style.typeChoose}>
                    <div className={style.creationSection}>
                        <h4 className={style.creationSectionTitle}>Введите название:</h4>
                        <input name="event-name" value={eventName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEventName(event.currentTarget.value)} type="text" className={style.inputText} spellCheck placeholder="Субботник" />
                    </div>
                </div>
            }
            <div onClick={handleSubmit} className={style.buttonSave}>Сохранить</div>
        </div>
    );
}

export default TimeTable;